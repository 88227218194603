import Button from "../components/button";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";

function columnsClients(simplified = true, editObj = null, deleteObj = null, showClient = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id ? row?.id : "",
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client ? row?.client : "",
      sortable: true,
      
    },
    {
      name: 'Last Name',
      selector: row => row?.lastName ? row?.lastName : "",
      sortable: true,
      omit: simplified
    },
    {
      name: 'Organization',
      selector: row => row?.organisation ? row?.organisation : "",
      sortable: true,
      omit: simplified
    },
    {
      name: 'Email Address',
      selector: row => row?.email ? row?.email : "",
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: row => row?.phone ? row?.phone : "",
      sortable: true,
    },
    // {
    //   name: 'Lead Source',
    //   selector: row => row?.source,
    //   sortable: true,
    //   omit: simplified
    // }
    {
      name: 'Lead Status',
      selector: row => row?.historical_matriz?.length 
        ? row?.historical_matriz[row?.historical_matriz.length - 1].newValue : '',
      sortable: true,
      omit: simplified
    },
    // {
    //   name: 'Lead Owner',
    //   selector: row => row?.owner,
    //   sortable: true,
    //   omit: simplified
    // },
    {
      name: 'Contact Date',
      selector: row => row?.date ? row?.date : "",
      sortable: true,
      omit: simplified
    },
    {
      name: 'Created',
      selector: row => row?.created_date ? row?.created_date : "",
      sortable: true,
      omit: simplified
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row?.id) }}><FiEdit2></FiEdit2></Button> <Button className="color-green btn btn-outline-secondary p-2 mx-2" title="Show all bookings" onClick={() => { showClient(row?.id) }}><AiOutlineSearch></AiOutlineSearch></Button> <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row?.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

function columnsNotifications() {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Category',
      selector: row => row?.category,
      sortable: true,
    },
    {
      name: 'Summary',
      selector: row => row?.summary,
      sortable: true,
    },
    {
      name: 'Time',
      selector: row => row?.time,
      sortable: true,
    },
  ]
  return header;
}

function columns_excel() {
  var header = [
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "client",
    title: "Client Name",
    width: "200px",
    id: "_1_column",
    isAccessible: true,
    ariaColumnIndex: 1,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "lastName",
    title: "Last Name",
    width: "150px",
    id: "_2_column",
    isAccessible: true,
    ariaColumnIndex: 2,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "organisation",
    title: "Organization",
    width: "200px",
    id: "_3_column",
    isAccessible: true,
    ariaColumnIndex: 3,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "email",
    title: "Email Address",
    id: "_4_column",
    isAccessible: true,
    ariaColumnIndex: 4,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "phone",
    title: "Phone Number",
    id: "_5_column",
    isAccessible: true,
    ariaColumnIndex: 5,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "leadSource.value",
    title: "Lead Source",
    id: "_6_column",
    isAccessible: true,
    ariaColumnIndex: 6,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "source",
    title: "Others Lead Source",
    width: "150px",
    id: "_7_column",
    isAccessible: true,
    ariaColumnIndex: 7,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "owner",
    title: "Lead Owner",
    id: "_8_column",
    isAccessible: true,
    ariaColumnIndex: 8,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "date",
    title: "Contact Date",
    id: "_9_column",
    isAccessible: true,
    ariaColumnIndex: 9,
  },
 ];
 return header;
}

function columns_trasport_excel() {
  var header = [
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "clientName",
    title: "Client Name",
    width: "200px",
    id: "_1_column",
    isAccessible: true,
    ariaColumnIndex: 1,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "startDate",
    title: "Start date",
    id: "_2_column",
    isAccessible: true,
    ariaColumnIndex: 2,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "startTime",
    title: "Start time",
    id: "_3_column",
    isAccessible: true,
    ariaColumnIndex: 3,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "endDate",
    title: "End date",
    id: "_4_column",
    isAccessible: true,
    ariaColumnIndex: 4,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "endTime",
    title: "End time",
    id: "_5_column",
    isAccessible: true,
    ariaColumnIndex: 5,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "shuttle.value",
    title: "Shuttle type",
    id: "_6_column",
    isAccessible: true,
    ariaColumnIndex: 6,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "pickUp.label",
    title: "Pick Up",
    id: "_7_column",
    isAccessible: true,
    ariaColumnIndex: 7,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "dropOff.label",
    title: "Drop off",
    id: "_8_column",
    isAccessible: true,
    ariaColumnIndex: 8,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "driverName",
    title: "Driver Name",
    id: "_9_column",
    isAccessible: true,
    ariaColumnIndex: 9,
  },
 ];
 return header;
}

function columns_lead_sources_excel() {
  var header = [
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "clientName",
    title: "Client Name",
    width: "200px",
    id: "_1_column",
    isAccessible: true,
    ariaColumnIndex: 1,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "leadSource.label",
    title: "Lead Source",
    id: "_2_column",
    isAccessible: true,
    ariaColumnIndex: 2,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "amount",
    title: "Amount",
    id: "_3_column",
    isAccessible: true,
    ariaColumnIndex: 3,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "extraAmount",
    title: "Extra Amount",
    id: "_4_column",
    isAccessible: true,
    ariaColumnIndex: 4,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "discountAmount",
    title: "Discount Amount",
    id: "_5_column",
    isAccessible: true,
    ariaColumnIndex: 5,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "taxes.label",
    title: "Tax Type",
    id: "_6_column",
    isAccessible: true,
    ariaColumnIndex: 6,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "taxAmount",
    title: "Tax Amount",
    id: "_7_column",
    isAccessible: true,
    ariaColumnIndex: 7,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "fuelSurchargeAmount",
    title: "Fuel Surcharge",
    id: "_8_column",
    isAccessible: true,
    ariaColumnIndex: 8,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "tipAmount",
    title: "Tip Amount",
    id: "_9_column",
    isAccessible: true,
    ariaColumnIndex: 9,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "total",
    title: "Total w/o tip",
    id: "_10_column",
    isAccessible: true,
    ariaColumnIndex: 10,
  },
 ];

 return header;
}

const paymentTypes = [
  { value: 'AMEX', label: 'AMEX' },
  { value: 'By The Flybook', label: 'By The Flybook' },
  { value: 'Cash', label: 'Cash' },
  { value: 'Cheque', label: 'Cheque' },
  { value: 'Credit', label: 'Credit' },
  { value: 'Debit', label: 'Debit' },
  { value: 'E-transfer', label: 'E-transfer' },
  { value: 'EFT', label: 'EFT' },
  { value: 'Gift Card', label: 'Gift Card' },
  { value: 'Invoiced via QB', label: 'Invoiced via QB' },
  { value: 'Mastercard', label: 'Mastercard' },
  { value: 'Refund', label: 'Refund' },
  { value: 'Revert', label: 'Revert' },
  { value: 'Stripe', label: 'Stripe' },
  { value: 'Stripe Online', label: 'Stripe Online' },
  { value: 'Visa', label: 'Visa' },
  { value: 'Other', label: 'Other' },
]

const refundTypes = [
  { value: 'AMEX', label: 'AMEX' },
  { value: 'Cash', label: 'Cash' },
  { value: 'Cheque', label: 'Cheque' },
  { value: 'Credit', label: 'Credit' },
  { value: 'Debit', label: 'Debit' },
  { value: 'E-transfer', label: 'E-transfer' },
  { value: 'EFT', label: 'EFT' },
  { value: 'Gift Card', label: 'Gift Card' },
  { value: 'Invoiced via QB', label: 'Invoiced via QB' },
  { value: 'Mastercard', label: 'Mastercard' },
  { value: 'Stripe', label: 'Stripe' },
  { value: 'Stripe Online', label: 'Stripe Online' },
  { value: 'Visa', label: 'Visa' },
  { value: 'Other', label: 'Other' },
]

const shuttleTypes = [
  { value: 'YYC', label: 'YYC' },
  { value: 'YXC', label: 'YXC' },
  { value: 'Private Party', label: 'Private Party' },
  { value: 'Private shuttle', label: 'Private shuttle' },
  { value: 'Private Wedding', label: 'Private Wedding' },
  { value: 'Other', label: 'Other' },
]

const rentalDurationHours = [
  { value: 1, label: '3 Hour(s)' },
  { value: 2, label: '6 Hour(s)' },
  { value: 3, label: '9 Hour(s)' },
  { value: 4, label: '12 Hour(s)' },
  { value: 5, label: '15 Hour(s)' },
  { value: 6, label: '18 Hour(s)' },
  { value: 7, label: '21 Hour(s)' },
  // { value: 8, label: '24 Hour(s)' },
]

const rentalDurationDays = [
  { value: 1, label: '1 Day(s)' },
  { value: 2, label: '2 Day(s)' },
  { value: 3, label: '3 Day(s)' },
  { value: 4, label: '4 Day(s)' },
  { value: 5, label: '5 Days(s)' },
  { value: 6, label: '6 Days(s)' },
  { value: 7, label: '7 Days(s)' },
  { value: 8, label: '8 Days(s)' },
  { value: 9, label: '9 Days(s)' },
  { value: 10, label: '10 Days(s)' },
  { value: 11, label: '11 Days(s)' },
  { value: 12, label: '12 Days(s)' },
  { value: 13, label: '13 Days(s)' },
  { value: 14, label: '14 Days(s)' },
  { value: 15, label: '15 Days(s)' },
  { value: 16, label: '16 Days(s)' },
  { value: 17, label: '17 Days(s)' },
  { value: 18, label: '18 Days(s)' },
  { value: 19, label: '19 Days(s)' },
  { value: 20, label: '20 Days(s)' },
  { value: 21, label: '21 Days(s)' },
  { value: 22, label: '22 Days(s)' },
  { value: 23, label: '23 Days(s)' },
  { value: 24, label: '24 Days(s)' },
  { value: 25, label: '25 Days(s)' },
  { value: 26, label: '26 Days(s)' },
  { value: 27, label: '27 Days(s)' },
  { value: 28, label: '28 Days(s)' },
  { value: 29, label: '29 Days(s)' },
  { value: 30, label: '30 Days(s)' },
]

const durationTypes = [
  { value: 'Daily', label: 'Daily' },
  { value: 'Hourly', label: 'Hourly' },
]

const rentalByItems = [
  { value: 'Minute', label: 'Minute' },
  { value: 'Hour', label: 'Hour' },
  { value: 'Day', label: 'Day' },
  { value: 'Week', label: 'Week' },
]

function numtoDate(numDates, isTrue = true) {
  var to1900 = isTrue ? 25567 + 1 : 25567;
  // 86400 is the number of seconds in a day, so we multiply by 1000 to get milliseconds.
  return new Date((numDates - to1900) * 86400 * 1000);
}

const monthNames = [
  "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December"
];

const dayOfWeekNames = [
  "Sunday", "Monday", "Tuesday",
  "Wednesday", "Thursday", "Friday", "Saturday"
];

function formatDate(date, patternStr) {
  try {
    if (!patternStr) {
      patternStr = 'yyyy-MM-dd';
    }
    var day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      h = hour % 12,
      hh = twoDigitPad(h),
      HH = twoDigitPad(hour),
      mm = twoDigitPad(minute),
      ss = twoDigitPad(second),
      aaa = hour < 12 ? 'AM' : 'PM',
      EEEE = dayOfWeekNames[date.getDay()],
      EEE = EEEE.substr(0, 3),
      dd = twoDigitPad(day),
      M = month + 1,
      MM = twoDigitPad(M),
      MMMM = monthNames[month],
      MMM = MMMM.substr(0, 3),
      yyyy = year + "",
      yy = yyyy.substr(2, 2)
      ;
    // checks to see if month name will be used
    patternStr = patternStr
      .replace('hh', hh).replace('h', h)
      .replace('HH', HH).replace('H', hour)
      .replace('mm', mm).replace('m', minute)
      .replace('ss', ss).replace('s', second)
      .replace('S', miliseconds)
      .replace('dd', dd).replace('d', day)

      .replace('EEEE', EEEE).replace('EEE', EEE)
      .replace('yyyy', yyyy)
      .replace('yy', yy)
      .replace('aaa', aaa);
    if (patternStr.indexOf('MMM') > -1) {
      patternStr = patternStr
        .replace('MMMM', MMMM)
        .replace('MMM', MMM);
    }
    else {
      patternStr = patternStr
        .replace('MM', MM)
        .replace('M', M);
    }

    return patternStr;
  } catch (error) {

  }
}

function twoDigitPad(num) {
  return num < 10 ? "0" + num : num;
}

const getCardType = [
  { value: 'Master Card', label: 'Master Card' },
  { value: 'Visa', label: 'Visa' },
  { value: 'American express', label: 'American express' },
  { value: 'Other', label: 'Other' },
]
  
export { numtoDate, monthNames, dayOfWeekNames, paymentTypes, refundTypes, shuttleTypes, rentalDurationHours, rentalDurationDays, 
  durationTypes, rentalByItems, getCardType, formatDate, twoDigitPad, columnsClients, columnsNotifications, columns_excel,
  columns_trasport_excel, columns_lead_sources_excel }

  export const roleVisit = 'visit';
  export const roleSuperAdmin = 'superAdmin';  
  export const privateCharter = 'Private Charter';
  export const YYC = 'YYC';  
  export const YYCLateDeparture = 'YYC (Late Departure)';
  export const YYCShuttleRCR = 'YYC (RCR)';
  export const YXC = 'YXC';
  export const YXCShuttle = 'YXC Shuttle';
